<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2"></h2>
        <router-link to="/system/admin/group" class="btn btn-primary align-self-center ms-3">
            {{ $t("pages.system.admin.adminGroups") }}
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr024.svg" />
            </span>
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.system.admin.title')"
        :subTitle="$t('pages.system.admin.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:name="{ row: record }">
            {{ record.firstname + ' ' + record.lastname }}
        </template>
        <template v-slot:email="{ row: record }">
            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1" v-if="record.email">
                {{ record.email }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchAdmin(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-if="record.is_editable">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])" v-if="record.is_deletable">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>

                <el-tooltip :content="$t('pages.system.admin.changePassword')" placement="top">
                    <a v-on:click="changePassword(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/general/gen047.svg" />
                        </span>
                    </a>
                </el-tooltip>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_admin" ref="addAdminModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="adminForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.group') }}</label>
                                <el-form-item prop="group_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.group_id" class="w-100" :placeholder="$t('pages.system.admin.groupSelect')">
                                        <el-option v-for="(group, groupIndex) in adminGroups" :key="groupIndex" :value="group.id" :label="group.name">{{ group.name }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.firstname') }}</label>
                                <el-form-item prop="firstname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.firstname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.lastname') }}</label>
                                <el-form-item prop="lastname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.lastname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.email') }}</label>
                                <el-form-item prop="email" :rules="$validation.getMessage(['requiredBlur', 'email'])">
                                    <el-input v-model="form.data.email" autocomplete="off"/>
                                </el-form-item>
                            </div>


                            <div class="fv-row mb-7" v-if="!form.updateStatus">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.password') }}</label>
                                <el-form-item prop="password" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.password" autocomplete="off"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_change_password" ref="changePasswordModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_change_password_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_change_password_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onChangePasswordSubmit()" :model="form.data" ref="changePasswordForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_change_password_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.currentPassword') }}</label>
                                <el-form-item prop="current_password" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.current_password" autocomplete="off" type="password"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.password') }}</label>
                                <el-form-item prop="password" :rules="rules.password">
                                    <el-input v-model="form.data.password" autocomplete="off" type="password"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.admin.cols.passwordConfirmation') }}</label>
                                <el-form-item prop="password_confirmation" :rules="rules.password_confirmation">
                                    <el-input v-model="form.data.password_confirmation" autocomplete="off" type="password"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "admin",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.system.admin.cols.name"),
                    scopedSlots: {customRender: "name"},
                },
                {
                    name: this.$t("pages.system.admin.cols.email"),
                    scopedSlots: {customRender: "email"},
                },
                {
                    name: this.$t("pages.system.admin.cols.group"),
                    key: "group.name"
                },
                {
                    name: this.$t("pages.system.admin.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t('pages.system.admin.cols.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            rules: {},
            selectedRowKeys: []
        }
    },
    created() {
        this.rules = {
            password: [{validator: this.validatePass, trigger: ['blur'] }],
            password_confirmation: [{validator: this.validatePass2, trigger: ['blur', 'change'] }],
        }
    },
    computed: {
        table() {
            return this.$store.state.system.admin.table;
        },
        adminGroups() {
            return this.$store.state.system.admin.group.table.data;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.admins"), [this.$t("menu.systemManagement")]);
        this.$store.dispatch('system/admin/get', {page: {}});
        this.$store.dispatch('system/admin/group/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        newAdmin(){
            this.form.updateStatus = false;
            this.form.data = {
                active: true
            };
            this.form.title = this.$t("pages.system.admin.newAdmin");
            this.showModal(this.$refs.addAdminModal);
        },
        fetchAdmin(record) {
            this.form.updateStatus = true;
            this.form.title =  this.$t("pages.system.admin.editAdmin")
            this.axios.get(this.endpoints['admin']+ '/' + record.id).then(response => {
                this.form.data = response.data.data;
                this.showModal(this.$refs.addAdminModal);
            });
        },
        changePassword(record) {
            this.form.title =  this.$t("pages.system.admin.changePassword")
            this.form.data = {
                id: record.id,
                password: '',
                password_confirmation: ''
            };
            this.showModal(this.$refs.changePasswordModal);
        },
        onSubmit(){
            this.$refs.adminForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['admin'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addAdminModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['admin'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addAdminModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        onChangePasswordSubmit(){
            this.$refs.changePasswordForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['admin_change_password'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.hideModal(this.$refs.changePasswordModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if(error.response.data.message == 'current_password_invalid') {
                                error.response.data.message = this.$t('pages.system.admin.responseMessages.currentPasswordInvalid');
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("system/admin/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newAdmin();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("system/admin/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("system/admin/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        validatePass(rule, value, callback)  {
            if (value === '') {
                callback(new Error(this.$t('validation.password')))
            } else {
                if (this.form.data.password_confirmation !== '') {
                    this.$refs.changePasswordForm.validateField('password_confirmation')
                }
                callback()
            }
        },
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.passwordConfirmation')))
            } else if (value !== this.form.data.password) {
                callback(new Error(this.$t('validation.dontMatchPassword')))
            } else {
                callback()
            }
        }
    }
}
</script>

<style scoped>

</style>